import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Trunks_VS_Cell from './../../../images/Trunks_VS_Cell.gif';
export const _frontmatter = {
  "path": "/Trunks_VS_Cell.gif.html"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div className="text-center">{children}</div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img className="mx-auto" src={Trunks_VS_Cell} />
    <p>{`SSJ Trunks slicing Imperfect Cell in half`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      